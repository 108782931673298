import {
  Datagrid,
  List,
  RichTextField,
  TopToolbar,
  sanitizeListRestProps,
} from "react-admin";

import React from "react";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Button } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";

const cardStyle = {
  width: 200,
  // minHeight: 300,
  margin: "0.5em",
  display: "flex",
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: "top",
  textAlign: "center",
};

const iconStyle = {
  color: "#d2d2d2",
  fontSize: 220,
};

export const ReportsList = (props) => {
  const { staticContext, ...rest } = props;

  return (
    <div style={{ margin: "1em", display: "flex" }}>
      {[
        {
          id: 1,
          name: "Alumni Report",
          link: "/#/report-alumni",
          image: "/images/reports/report1.svg",
        },
        {
          id: 2,
          name: "Country Report",
          link: "/#/report-country",
          image: "/images/reports/report2.svg",
        },
        {
          id: 3,
          name: "Roster Report",
          link: "/#/report-roster",
          image: "/images/reports/report3.svg",
        },
        {
          id: 4,
          name: "Grades List",
          link: "/#/report-grades",
          image: "/images/reports/report4.svg",
        },
        {
          id: 4,
          name: "Organizations List",
          link: "/#/report-organizations",
          image: "/images/reports/report5.svg",
        },
      ].map((item) => (
        <Card
          key={item.id}
          style={cardStyle}
        >
          <CardHeader title={item.name} />
          <CardContent
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundRepeat: 'no-repeat',
              width: 50,
              height: 50,
              backgroundSize: "contain",
            }}
          ></CardContent>
          <CardActions style={{ textAlign: "right" }}>
            <Button variant="text" href={item.link} style={{fontSize: 12}}>View report</Button>
          </CardActions>
        </Card>
      ))}
    </div>
  );
};
