import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
} from "react-admin";
import lodash from "lodash";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
  </Filter>
);

export const CountryList = (props) => (
  <List {...props} filters={<Filters />}>
    <Datagrid rowClick="show">
      <TextField source="code" />
      <TextField source="name" />
      <FunctionField
        source="total_students"
        label="Total Students"
        render={(record) => {
          if (record && record.total_students) {
            let counter = 0;
            lodash.keys(record.total_students).forEach((key) => {
              counter += Number(record.total_students[key]);
            });

            return counter;
          }
        }}
      />
    </Datagrid>
  </List>
);

export default CountryList;
