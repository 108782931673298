import React from 'react';
import { TextField, EmailField, Show, SimpleShowLayout } from 'react-admin';

const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source='last_name' />
            <TextField source='home_phone' />
            <EmailField source='email' />
            <TextField source='first_name' />
            <TextField source='username' />
            <TextField source='avatar' />
            <TextField source='mobile_phone' />
        </SimpleShowLayout>
    </Show>
);

export default UserShow;
