import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Notification } from 'react-admin';
import { Sidebar, Topbar, Footer } from './components';
import { useSelector } from "react-redux";
import Preloader from './components/Preloader';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 105,
        padding: 25,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
    },
    shiftContent: {
        paddingLeft: 265,
    },
    content: {
        height: '100%',
    },
}));

const Main = (props) => {
    const { children } = props;
    const {loading} = useSelector((state) => state.admin);

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop,
            })}
        >
            <Topbar onSidebarOpen={handleSidebarOpen} />
            <Sidebar
                onClose={handleSidebarClose}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
            />
            <main className={classes.content}>
                {children}
                <Notification />
                {loading ? <Preloader /> : null}
            </main>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;
