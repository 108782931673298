import {
  Datagrid,
  List,
  RichTextField,
  TopToolbar,
  sanitizeListRestProps,
  ReferenceInput,
  Filter,
  SelectInput,
  useListContext,
} from "react-admin";

import Avatar from "../../components/Avatar";
import Empty from "./Empty";
import { ExportPdfButton } from "./ReportActions";
import { GoToProgramButton } from "../StudentEnrollments/StudentEnrollmentActions";
import React, { useEffect } from "react";
import { GetReference } from "../../../helpers";
import { useSelector } from "react-redux";

const StudentGeneralField = ({ source, record = {} }) => (
  <div>
    <p>{record.fullname}</p>
    <p>{record.title}</p>
    <p>{record.country_details && record.country_details.name}</p>
  </div>
);

const Filters = (props) => {
  const { setFilters } = useListContext();

  useEffect(() => {
    return () => {
      setFilters({});
    };
  }, []);

  return (
    <Filter {...props}>
      {GetReference("programs") && (
        <ReferenceInput
          source="program_id"
          reference="programs"
          alwaysOn
          variant="outlined"
        >
          <SelectInput optionText="name" fullWidth variant="outlined" />
        </ReferenceInput>
      )}
    </Filter>
  );
};

const RosterActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  program_id,
  total,
  ...rest
}) => {
  const getRosterContent = () => {
    const rosterList = document.getElementById("roster-list");
    return rosterList ? rosterList.outerHTML : null;
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {program_id && <GoToProgramButton program_id={program_id} />}
      <ExportPdfButton getContent={getRosterContent} exportTitle="Roster Report" />
    </TopToolbar>
  );
};

export const Roster = (props) => {
  const { program_id } = props.match.params;
  const { staticContext, hasFilter, ...rest } = props;
  const { loading } = useSelector((state) => state.admin);

  if (hasFilter) {
    return (
      <List
        perPage={1000}
        bulkActionButtons={false}
        pagination={<Empty />}
        actions={<RosterActions program_id={program_id} />}
        filters={<Filters />}
        {...rest}
      >
        {!loading ? (
          <Datagrid id="roster-list">
            <Avatar width={70} />
            <StudentGeneralField source="fullname" label="Summary" />
            <RichTextField source="education_summary" />
          </Datagrid>
        ) : null}
      </List>
    );
  }

  return (
    <List
      perPage={1000}
      bulkActionButtons={false}
      filter={{ program_id: program_id }}
      pagination={<Empty />}
      actions={<RosterActions program_id={program_id} />}
      {...rest}
    >
      {!loading ? (
        <Datagrid id="roster-list">
          <Avatar width={70} />
          <StudentGeneralField source="fullname" label="Summary" />
          <RichTextField source="education_summary" />
        </Datagrid>
      ) : null}
    </List>
  );
};
