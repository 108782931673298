import {Create, Datagrid, Edit, Filter, List, NumberInput, ReferenceField, ReferenceInput, SelectInput, Show, SimpleForm, SimpleShowLayout, TextField, TextInput} from 'react-admin';

import React from 'react';

const OrganizationList = props => (
    <List {...props} filters={<OrganizationFilter />}>
        <Datagrid rowClick="show">
            <TextField disabled source="id" />
            <TextField source="country.name" />
            <TextField source="address" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

const OrganizationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" />
        <TextInput label="Address" source="address" />
        <ReferenceInput
          label="Country"
          source="country_id"
          reference="countries"
          perPage={1000}
          sort={'ASC'}
        >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>
    </Filter>
);

export default OrganizationList;
