import ProgramList from './ProgramList';
import ProgramShow from './ProgramShow';
import ProgramCreate from './ProgramCreate';
import ProgramEdit from './ProgramEdit';

export default {
    list: ProgramList,
    show: ProgramShow,
    create: ProgramCreate,
    edit: ProgramEdit
};
