import React from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
  import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
  import MoneyIcon from '@material-ui/icons/Money';
  import { red } from '@material-ui/core/colors';
import { useGetList } from "react-admin";

import PublicIcon from '@material-ui/icons/Public';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import lodash from 'lodash';

const ICONS = {
  'countries': <PublicIcon style={{color: '#036eac'}}/>,
  'organizations': <HomeWorkIcon  style={{color: '#036eac'}}/>,
  'programs': <AssignmentIcon  style={{color: '#036eac'}}/>,
  'students': <PeopleIcon  style={{color: '#036eac'}}/>,
}
  const CounterWidget = ({title, value, resource, ...props}) => {
    const { data, ids, loading, error } = useGetList(
      resource,
      { page: 1, perPage: 1000 },
      {}
    );

    if (loading) {
      return <p>Loading...</p>;
    }
    if (error) {
    return <p>Error loading data...</p>;
    }

    const totalCount = ids.filter((id) => !lodash.isEmpty(data[id].total_students))

    return (
    <Card
      sx={{ height: '100%' }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'space-around',
          alignItems: "center" }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              style={{ textTransform: 'uppercase'}}
            >
              {resource}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {resource === 'countries' ? totalCount.length : ids.length}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: red[600],
                height: 56,
                width: 56
              }}
            >
              {ICONS[resource]}
            </Avatar>
          </Grid>
        </Grid>
        
      </CardContent>
    </Card>
    )
  }
  
  export default CounterWidget;