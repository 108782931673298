import { Alumni, Country, Roster } from "./views/pages/Reports";

import React from "react";
import { Route } from "react-router-dom";
import { Grades } from "./views/pages/Reports/Grades";
import { Organizations } from "./views/pages/Reports/Organizations";
import Dashboard from "./views/pages/Dashboard";
import StudentRegistration from "./views/pages/Students/StudentRegistration";

export default [
  <Route
    exact
    path="/report-roster/:program_id"
    render={(routeProps) => (
      <Roster
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="students"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/report-roster/"
    render={(routeProps) => (
      <Roster
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="students"
        basePath={routeProps.match.url}
        hasFilter
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/report-alumni/:program_id"
    render={(routeProps) => (
      <Alumni
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="students"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/report-alumni"
    render={(routeProps) => (
      <Alumni
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="students"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/report-country"
    render={(routeProps) => (
      <Country
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="countries"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/report-grades/"
    render={(routeProps) => (
      <Grades
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="student-enrollments"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/report-grades/:program_id"
    render={(routeProps) => (
      <Grades
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="student-enrollments"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/report-organizations"
    render={(routeProps) => (
      <Organizations
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
        hasList={false}
        resource="organizations"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path="/dashboard"
    render={(routeProps) => <Dashboard {...routeProps} />}
  />,
  <Route
    exact
    path="/registration/:params"
    render={(routeProps) => <StudentRegistration {...routeProps} />}
    noLayout
  />,
  <Route
    exact
    path="/registration-success"
    render={(routeProps) => <h1>Thank you for registration!</h1>}
    noLayout
  />,
];
