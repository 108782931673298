import { Box, Card, CardContent, Typography } from "@material-ui/core";
import {
  DateInput,
  Edit,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
  FormDataConsumer,
  Toolbar,
  useNotify,
  useMutation,
  required,
  email,
} from "react-admin";

import Avatar from "../../components/Avatar";
import FileUploader from "../../components/FileUploader";
import { FormSpy } from "react-final-form";
import React from "react";

const StudentsEdit = (props) => {
  return (
    <Edit undoable={false} title={"Student Record"} component="div" {...props}>
      <StudentsEditForm variant="outlined" />
    </Edit>
  );
};
const StudentsEditForm = (props) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    Personal Information
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="fullname"
                        resource="students"
                        fullWidth
                        variant="outlined"
                        validate={required()}
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <SelectInput
                        source="gender"
                        choices={[
                          { id: "male", name: "Male" },
                          { id: "female", name: "Female" },
                        ]}
                        optionText="name"
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                  <TextInput
                    type="email"
                    source="email"
                    resource="students"
                    fullWidth
                    variant="outlined"
                    validate={[email(), required()]}
                  />
                  <TextInput
                    type="alternative_email"
                    source="alternative_email"
                    resource="students"
                    validate={[email()]}
                    variant="outlined"
                    fullWidth
                  />
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <DateInput
                        source="birth_date"
                        resource="students"
                        fullWidth
                        variant="outlined"
                        validate={required()}
                      />
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Contact Information
                  </Typography>
                  <TextInput
                    source="street_address"
                    resource="students"
                    variant="outlined"
                    multiline
                    fullWidth
                    validate={required()}
                  />
                  <TextInput
                    source="additional_address"
                    resource="students"
                    variant="outlined"
                    multiline
                    fullWidth
                  />
                  <TextInput
                    source="mailing_address"
                    resource="students"
                    variant="outlined"
                    multiline
                    fullWidth
                  />

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Phones
                  </Typography>

                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="mobile_phone"
                        resource="students"
                        variant="outlined"
                        fullWidth
                        validate={required()}
                      />
                      <TextInput
                        source="home_phone"
                        resource="students"
                        variant="outlined"
                        fullWidth
                      />
                      <TextInput
                        source="office_phone"
                        resource="students"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="fax"
                        resource="students"
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Organization Information
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <ReferenceInput source="country_id" reference="countries">
                        <SelectInput
                          optionText="name"
                          variant="outlined"
                          validate={required()}
                        />
                      </ReferenceInput>
                      <TextInput
                        source="position"
                        resource="students"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="organization"
                        variant="outlined"
                        fullWidth
                      />

                      <FormDataConsumer>
                        {({ formData, ...rest }) => (
                          <ReferenceInput
                            source="organization_id"
                            reference="organizations"
                            validate={required()}
                          >
                            <SelectInput
                              optionText="name"
                              fullWidth
                              variant="outlined"
                            />
                          </ReferenceInput>
                        )}
                      </FormDataConsumer>
                      {/* <TextInput
                        source="organization_details"
                        fullWidth
                        variant="outlined"
                      /> */}
                    </Box>
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Educational Information
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      {/* <TextInput
                        source="transcript"
                        resource="students"
                        variant="outlined"
                        fullWidth
                      /> */}
                      <TextInput
                        source="citizenship"
                        variant="outlined"
                        resource="referrer"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        source="referrer"
                        variant="outlined"
                        resource="referrer"
                        fullWidth
                        validate={required()}
                      />
                      <TextInput
                        source="funding_source"
                        variant="outlined"
                        resource="referrer"
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="education_summary"
                        variant="outlined"
                        resource="students"
                        fullWidth
                      />
                      <TextInput
                        variant="outlined"
                        source="current_employment_summary"
                        resource="students"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: "1em" }}
                  mt={{ xs: "1em", lg: 0 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Student Information
                  </Typography>
                  <div>
                    <Avatar isEditing {...props} />
                    <FileUploader
                      label="Avatar"
                      tag="avatar"
                      placeholder="Drop or select file"
                      {...props}
                    ></FileUploader>
                  </div>
                  <br />
                  <br />
                  <br />
                  <Typography variant="h6" gutterBottom>
                    Transcript
                  </Typography>
                  <FileUploader
                    label="Transcript"
                    tag="transcript"
                    placeholder="Drop or select file"
                    {...props}
                  ></FileUploader>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={false}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="students"
            />
          </form>
        </Card>
      )}
    />
  );
};

export default StudentsEdit;
