import {
    Datagrid,
    DateField,
    EditButton,
    Filter,
    List,
    SelectField,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
} from 'react-admin';
import { PROGRAM_STATUS, PROGRAM_STREAM, PROGRAM_TYPES } from '../../../constants/common';

import React from 'react';
import { StudentsButton } from './ProgramActions';
import { generateChoices } from '../../../helpers';

const ProgramsFilter = (props) => (
    <Filter {...props}>
        <TextInput label='Name' source='name' />
        <SelectInput label='Status' source='status' choices={generateChoices(PROGRAM_STATUS)} />
        <SelectInput label='Stream' source='stream' choices={generateChoices(PROGRAM_STREAM)} />
        <SelectInput label='Program Type' source='program_type' choices={generateChoices(PROGRAM_TYPES)} />
    </Filter>
);

const ProgramList = (props) => {
    return (
        <List {...props} filters={<ProgramsFilter />}>
            <Datagrid>
                <TextField source='id' />
                <TextField source='name' />
                <SelectField source='status' choices={generateChoices(PROGRAM_STATUS)} />
                <SelectField source='stream' choices={generateChoices(PROGRAM_STREAM)} />
                <SelectField source='program_type' choices={generateChoices(PROGRAM_TYPES)} />
                <DateField source='start_date' />
                <DateField source='end_date' />
                <ShowButton />
                <EditButton />
                <StudentsButton {...props} />
            </Datagrid>
        </List>
    );
};

export default ProgramList;
