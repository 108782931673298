import {
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  RichTextField,
} from "react-admin";
import {
  PROGRAM_STATUS,
  PROGRAM_STREAM,
  PROGRAM_TYPES,
} from "../../../constants/common";
import {
  ProgramPublishButton,
  ProgramCompleteButton,
  ProgramRoasterButton,
  ProgramStartButton,
  StudentsButton,
  ProgramAlumniButton,
  ProgramGradesButton,
} from "./ProgramActions";
import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { generateChoices } from "../../../helpers";
import Enrollments from "../Dashboard/components/Enrollments";
import { CopyToClipboard } from "react-copy-to-clipboard";
import base64 from "base-64";
import RegistrationLink from './RegistrationLink'


const ProgramActions = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {data && (
        <Fragment>
          <DeleteWithConfirmButton
            basePath={basePath}
            record={data}
            resource={resource}
            undoable={false}
          />
          <ProgramPublishButton record={data} />
          <ProgramStartButton record={data} />
          <ProgramCompleteButton record={data} />
          <StudentsButton record={data} />
          <ProgramRoasterButton record={data} />
          <ProgramAlumniButton record={data} />
          <ProgramGradesButton record={data} />
        </Fragment>
      )}
    </TopToolbar>
  );
};

const ProgramShow = (props) => (
  <Show actions={<ProgramActions />} {...props}>
    <SimpleShowLayout style={{ position: "relative" }}>
      <TextField source="id" />
      <TextField source="name" />
      <SelectField source="status" choices={generateChoices(PROGRAM_STATUS)} />
      <SelectField source="stream" choices={generateChoices(PROGRAM_STREAM)} />
      <SelectField
        source="program_type"
        choices={generateChoices(PROGRAM_TYPES)}
      />
      <DateField source="start_date" />
      <DateField source="end_date" />

      <Enrollments
        style={{ position: "absolute", top: 25, right: 25, width: "50%" }}
      />

      <RichTextField source="email_template" />

      <RegistrationLink {...props} />

    </SimpleShowLayout>
  </Show>
);

export default ProgramShow;
