import React from "react";
import { Create, SimpleForm, TextInput, FileInput, FileField } from "react-admin";
import { useFormState } from 'react-final-form'

const CountryCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm variant="outlined">
        <TextInput source="code" />
        <TextInput source="name" />
        <FileInput
          source="files"
          label="Related files"
          accept="application/pdf"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default CountryCreate;
