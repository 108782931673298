import {
    Button,
    DateField,
    DeleteWithConfirmButton,
    EditButton,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useNotify,
    useRedirect,
    useUpdate,
} from 'react-admin';
import { ENROLLMENT_STATUS, PROGRAM_STREAM, PROGRAM_TYPES } from '../../../constants/common';
import { GoToProgramButton, GoToStudentButton } from './StudentEnrollmentActions';
import React, { Fragment } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { generateChoices } from '../../../helpers';

const ActionButton = ({ record, status, label, icon }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [approve, { loading }] = useUpdate(
        'student-enrollments',
        record.id,
        { status: status },
        {},
        {
            onSuccess: ({ data }) => {
                redirect('/student-enrollments/' + record.id + '/show');
                notify('Student admission is approved', 'info');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return (
        <Button label={label} onClick={approve} disabled={loading}>
            {icon}
        </Button>
    );
};

ActionButton.defaultProps = {
    icon: <CheckIcon />,
};

const StudentEnrollmentActions = ({ basePath, data, resource, location }) => {
    return (
        <TopToolbar>
            {data && (
                <Fragment>
                    <GoToStudentButton record={data} />
                    <GoToProgramButton program_id={data.program_id} />
                    <DeleteWithConfirmButton basePath={basePath} record={data} resource={resource} undoable={false} />
                    {data.status === 'pre_admission' && <ActionButton label='Admit' record={data} status='admitted' />}
                    {data.status === 'admitted' && (
                        <ActionButton label='Funding Confirmed' record={data} status='funding_confirmed' />
                    )}
                    {data.status !== 'graduated' && data.status !== 'rejected' && (
                        <ActionButton label='Reject' record={data} status='rejected' icon={<ClearIcon />} />
                    )}
                </Fragment>
            )}
        </TopToolbar>
    );
};

const StudentEnrollmentShow = (props) => (
    <Show actions={<StudentEnrollmentActions location={props.location} />} {...props}>
        <SimpleShowLayout>
            <TextField label='Full Name' source='student.fullname' />
            <DateField label='Birth Date' source='student.birth_date' />
            <TextField label='Program Name' source='program.name' />
            <DateField label='Start Date' source='program.start_date' />
            <DateField label='End Date' source='program.end_date' />
            <SelectField label='Stream' source='program.stream' choices={generateChoices(PROGRAM_STREAM)} />
            <SelectField label='Program Type' source='program.program_type' choices={generateChoices(PROGRAM_TYPES)} />
            <SelectField source='status' choices={generateChoices(ENROLLMENT_STATUS)} />
            <TextField source='rank' />
            <TextField source='grade' />
        </SimpleShowLayout>
    </Show>
);

export default StudentEnrollmentShow;
