import {
  Datagrid,
  DateField,
  EmailField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  downloadCSV,
} from "react-admin";

import React from "react";

import jsonExport from "jsonexport/dist";

const exporter = (students) => {
  const studentsForExport = students.map((student) => {
    // const { backlinks, author, ...studentForExport } = student; // omit backlinks and author
    const studentForExport = {
      fullname: student.fullname,
      gender: student.gender,
      email: student.email,
      alternative_email: student.alternative_email,
      birth_date: student.birth_date,
      street_address: student.street_address,
      additional_address: student.additional_address,
      mailing_address: student.mailing_address,
      mobile_phone: student.mobile_phone,
      home_phone: student.home_phone,
      fax: student.fax,
      country: student.country_details && student.country_details.name,
      organization: student.organization_details && student.organization_details.name,
      position: student.position,
      education_summary: student.education_summary,
      current_employment_summary: student.current_employment_summary,
    };
    return studentForExport;
  });
  jsonExport(
    studentsForExport,
    {
      headers: [
        "fullname",
        "gender",
        "email",
        "alternative_email",
        "birth_date",
        "street_address",
        "additional_address",
        "mailing_address",
        "mobile_phone",
        "home_phone",
        "fax",
        "country",
        "organization",
        "position",
        "education_summary",
        "current_employment_summary",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "students"); // download as 'posts.csv` file
    }
  );
};

const StudentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Full Name" source="fullname" />
    <TextInput label="Organization" source="organization" />
    <SelectInput
      source="gender"
      choices={[
        { id: "male", name: "Male" },
        { id: "female", name: "Female" },
      ]}
    />
    <ReferenceInput
      label="Country"
      source="country_id"
      reference="countries"
      perPage={1000}
      sort={"ASC"}
    >
      <SelectInput optionText="name" variant="outlined" />
    </ReferenceInput>
    <ReferenceInput
      label="Organization"
      source="organization_id"
      reference="organizations"
      perPage={1000}
      sort={"ASC"}
    >
      <SelectInput optionText="name" variant="outlined" />
    </ReferenceInput>
    <TextInput label="Position" source="position" />
  </Filter>
);

export const StudentsList = (props) => (
  <List {...props} filters={<StudentsFilter />} exporter={exporter}>
    <Datagrid rowClick="show">
      <TextField disabled source="id" />
      <TextField source="fullname" />
      <TextField source="gender" />
      <TextField source="position" />
      <TextField source="organization" label="Initial organization" />
      <EmailField source="email" />
      <DateField source="birth_date" />
      <ReferenceField source="organization_id" reference="organizations">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default StudentsList;
