import CountryList from './CountyList';
import CountryEdit from './CountryEdit';
import CountryShow from './CountryShow';
import CountryCreate from './CountryCreate';

export default {
    list: CountryList,
    edit: CountryEdit,
    show: CountryShow,
    create: CountryCreate
};
