import { Button, useNotify, useDataProvider } from "react-admin";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import React from "react";

export const ExportPdfButton = ({ getContent, exportTitle, ...props }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleExportClick = () => {
    const content = getContent();
    if (content) {
      dataProvider.export("projects", {
        data: {
          export_name: exportTitle,
          content: getContent(),
        },
      });
    } else {
      notify(`The page is not loaded fully`, "warning");
    }
  };

  return (
    <Button label="Export PDF" onClick={handleExportClick}>
      {<PictureAsPdfIcon />}
    </Button>
  );
};
