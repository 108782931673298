import {
  Datagrid,
  Filter,
  List,
  ListBase,
  ReferenceInput,
  RichTextField,
  useListContext,
  SelectInput,
  TextField,
  TopToolbar,
  FunctionField,
  sanitizeListRestProps,
} from "react-admin";
import { GetReference, parseQuery } from "../../../helpers";

import Avatar from "../../components/Avatar";
import Box from "@material-ui/core/Box";
import Empty from "./Empty";
import { ExportPdfButton } from "./ReportActions";
import { PROGRAM_TYPES } from "../../../constants/common";
import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { cloneElement } from "react";
import { generateChoices } from "../../../helpers";
import { isEmpty } from "lodash";
import lodash from "lodash";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    padding: 25,
  },
  title: {
    fontSize: 35,
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 25,
    marginBottom: 25,
  },
  headRow: {
    display: "flex",
    borderBottom: "2px solid black",
    flexGrow: 1,
    padding: "5px 10px",
    "& p": {
      fontWeight: "bold",
    },
  },
  row: {
    display: "flex",
    borderBottom: "2px solid black",
    flexGrow: 1,
    padding: "15px 10px",
  },
  counter: {
    width: 50,
  },
  column: {
    width: "50%",
  },
});

const stylesEsport = {
  root: "padding: 25px;",
  title: "font-size: 35px; margin-bottom: 15px",
  subtitle: "font-size: 25px; margin-bottom: 25px;",
  headRow:
    "display: flex; border-bottom: 2px solid black; flex-grow:1; padding: 5px 10px; ",
  row:
    "display: flex; border-bottom: 2px solid black; flex-grow:1; padding: 15px 10px; ",
  counter: "width: 50px;",
  column: "width: 50%;",
};

const OgranizationsCustom = ({ ids, data, basePath, translate, ...props }) => {
  const classes = useStyles();
  const items = ids.map((id) => data[id]);

  return (
    <div className={classes.root} id={props.id}>
      <h2 className={classes.title}>Organizations in Ghana</h2>
      <h3 className={classes.subtitle}>Where are our alumni</h3>
      <div className={classes.headRow}>
        <div className={classes.column}>
          <p>Organization contacts details</p>
        </div>
        <div className={classes.column} style={{ textAlign: "right" }}>
          <p>Number of alumni in organizations</p>
        </div>
      </div>
      {items.map((item, idx) => (
        <div className={classes.row}>
          <div className={classes.counter}>{idx + 1}.</div>
          <div className={classes.column}>
            <p>{item.name}</p>
            <p>{item.address}</p>
          </div>
          <div className={classes.column}>
            {item.students && item.students.length > 0 && (
              <div>
                <p>{item.students[0].fullname}</p>
                <p>{item.students[0].position}</p>
                <p>
                  {item.students[0].email || item.students[0].alternative_email}
                </p>
                <p>
                  {item.students[0].mobile_phone ||
                    item.students[0].office_phone ||
                    item.students[0].home_phone}
                </p>
              </div>
            )}
          </div>
          {item.students && item.students.length > 0 && (
            <div className={classes.counter} style={{ fontStyle: "italic" }}>
              {item.students && item.students.length}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const AlumniActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  program_id,
  total,
  ...rest
}) => {
  const classes = useStyles();
  const getAlumniContent = () => {
    const rosterList = document.getElementById("organizations-list");
    return rosterList
      ? "<style>" +
          lodash
            .keys(classes)
            .map((key) => `.${classes[key]} {${stylesEsport[key]}}`)
            .join("") +
          " </style>" +
          rosterList.outerHTML
      : null;
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportPdfButton getContent={getAlumniContent} exportTitle="Organizations Report" />
    </TopToolbar>
  );
};

const AlumniFilter = (props) => {
  const { setFilters } = useListContext();

  useEffect(() => {
    return () => {
      setFilters({});
    };
  }, []);

  return (
    <Filter {...props}>
      {GetReference("countries") && (
        <ReferenceInput
          alwaysOn
          allowEmpty={true}
          label="Country"
          source="country_id"
          reference="countries"
          perPage={1000}
          sort={'ASC'}
        >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export const Organizations = (props) => {
  const { location, staticContext, ...rest } = props;
  const params = parseQuery(location.search.substr(1).split("&"));
  const filter = params.filter ? JSON.parse(params.filter) : {};
  const {loading} = useSelector((state) => state.admin);

  return filter.country_id && !loading ? (
    <List
      perPage={1000}
      bulkActionButtons={false}
      pagination={<Empty />}
      actions={<AlumniActions />}
      filters={<AlumniFilter />}
      empty={<Empty />}
      {...rest}
    >
      {/* <OgranizationsCustom id="organizations-list" /> */}
      <Datagrid id="organizations-list" style={{ textAlign: 'center'}}>
        <FunctionField
          label="Organization contacts details"
          sortable={false}
          render={(record) => (
            <div>
              <p>{record.name}</p>
              <p>{record.address}</p>
            </div>
          )}
        />
        <FunctionField
          label="Alamni"
          sortable={false}
          render={(item) => (
            <div  >
            {item.students && item.students.length > 0 && (
              <div>
                <p>{item.students[0].fullname}</p>
                <p>{item.students[0].position}</p>
                <p>
                  {item.students[0].email || item.students[0].alternative_email}
                </p>
                <p>
                  {item.students[0].mobile_phone ||
                    item.students[0].office_phone ||
                    item.students[0].home_phone}
                </p>
              </div>
            )}
          </div>
          )}
        />
        <FunctionField
          label="Number of alumni in organizations"
          sortable={false}
          render={(record) => (
            record.students ? record.students.length : '-'
          )}
        />
      </Datagrid>
    </List>
  ) : (
    <List
      perPage={1000}
      bulkActionButtons={false}
      pagination={false}
      actions={<AlumniActions />}
      filters={<AlumniFilter />}
      {...rest}
    >
      <ListBase>
        <h1>{props.title}</h1>
        <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            Please select Country first.
          </Typography>
        </Box>
      </ListBase>
    </List>
  );
};
