import React from "react";
import { Edit, SimpleForm, TextInput, FileInput, FileField } from "react-admin";

export const CountryEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm variant="outlined">
      <TextInput source="code" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export default CountryEdit;
