export const PROGRAM_TYPES = {
  PIAR: "PIAR",
  FIPEG: "FIPEG",
  QUEENS: "QUEENS",
  HARVARD: "HARVARD",
};

export const PROGRAM_STREAM = {
  regular: "Regular",
  trainer: "Training of trainers",
};

export const PROGRAM_STATUS = {
  draft: "Draft",
  published: "Published",
  started: "Started",
  completed: "Completed",
};

export const ENROLLMENT_STATUS = {
  pre_admission: "Pre-Admission",
  admitted: "Admitted",
  funding_confirmed: "Funding Confirmed",
  graduated: "Graduated",
  rejected: "Rejected",
};

export const COUNTRIES = [
  { name: "Afghanistan", code: "AF", total_students: { PIAR: 2 }, id: 1 },
  { name: "Aland Islands", code: "AX", total_students: {}, id: 2 },
  { name: "Albania", code: "AL", total_students: {}, id: 3 },
  { name: "Algeria", code: "DZ", total_students: {}, id: 4 },
  { name: "American Samoa", code: "AS", total_students: {}, id: 5 },
  { name: "Andorra", code: "AD", total_students: {}, id: 6 },
  { name: "Angola", code: "AO", total_students: {}, id: 7 },
  { name: "Anguilla", code: "AI", total_students: {}, id: 8 },
  { name: "Antarctica", code: "AQ", total_students: {}, id: 9 },
  { name: "Antigua and Barbuda", code: "AG", total_students: {}, id: 10 },
  { name: "Argentina", code: "AR", total_students: {}, id: 11 },
  { name: "Armenia", code: "AM", total_students: {}, id: 12 },
  { name: "Aruba", code: "AW", total_students: {}, id: 13 },
  { name: "Australia", code: "AU", total_students: {}, id: 14 },
  { name: "Austria", code: "AT", total_students: {}, id: 15 },
  { name: "Azerbaijan", code: "AZ", total_students: {}, id: 16 },
  { name: "Bahamas", code: "BS", total_students: {}, id: 17 },
  { name: "Bahrain", code: "BH", total_students: {}, id: 18 },
  { name: "Bangladesh", code: "BD", total_students: {}, id: 19 },
  { name: "Barbados", code: "BB", total_students: {}, id: 20 },
  { name: "Belarus", code: "BY", total_students: {}, id: 21 },
  { name: "Belgium", code: "BE", total_students: {}, id: 22 },
  { name: "Belize", code: "BZ", total_students: {}, id: 23 },
  { name: "Benin", code: "BJ", total_students: {}, id: 24 },
  { name: "Bermuda", code: "BM", total_students: {}, id: 25 },
  { name: "Bhutan", code: "BT", total_students: {}, id: 26 },
  { name: "Bolivia", code: "BO", total_students: {}, id: 27 },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    total_students: {},
    id: 28,
  },
  { name: "Bosnia and Herzegovina", code: "BA", total_students: {}, id: 29 },
  { name: "Botswana", code: "BW", total_students: {}, id: 30 },
  { name: "Bouvet Island", code: "BV", total_students: {}, id: 31 },
  { name: "Brazil", code: "BR", total_students: {}, id: 32 },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    total_students: {},
    id: 33,
  },
  { name: "Brunei Darussalam", code: "BN", total_students: {}, id: 34 },
  { name: "Bulgaria", code: "BG", total_students: {}, id: 35 },
  { name: "Burkina Faso", code: "BF", total_students: {}, id: 36 },
  { name: "Burundi", code: "BI", total_students: {}, id: 37 },
  { name: "Cambodia", code: "KH", total_students: {}, id: 38 },
  { name: "Cameroon", code: "CM", total_students: {}, id: 39 },
  { name: "Canada", code: "CA", total_students: {}, id: 40 },
  { name: "Cape Verde", code: "CV", total_students: {}, id: 41 },
  { name: "Cayman Islands", code: "KY", total_students: {}, id: 42 },
  { name: "Central African Republic", code: "CF", total_students: {}, id: 43 },
  { name: "Chad", code: "TD", total_students: {}, id: 44 },
  { name: "Chile", code: "CL", total_students: {}, id: 45 },
  { name: "China", code: "CN", total_students: {}, id: 46 },
  { name: "Christmas Island", code: "CX", total_students: {}, id: 47 },
  { name: "Cocos (Keeling) Islands", code: "CC", total_students: {}, id: 48 },
  { name: "Colombia", code: "CO", total_students: {}, id: 49 },
  { name: "Comoros", code: "KM", total_students: {}, id: 50 },
  { name: "Congo", code: "CG", total_students: {}, id: 51 },
  {
    name: "Congo, Democratic Republic of the Congo",
    code: "CD",
    total_students: {},
    id: 52,
  },
  { name: "Cook Islands", code: "CK", total_students: {}, id: 53 },
  { name: "Costa Rica", code: "CR", total_students: {}, id: 54 },
  { name: "Cote D'Ivoire", code: "CI", total_students: {}, id: 55 },
  { name: "Croatia", code: "HR", total_students: {}, id: 56 },
  { name: "Cuba", code: "CU", total_students: {}, id: 57 },
  { name: "Curacao", code: "CW", total_students: {}, id: 58 },
  { name: "Cyprus", code: "CY", total_students: {}, id: 59 },
  { name: "Czech Republic", code: "CZ", total_students: {}, id: 60 },
  { name: "Denmark", code: "DK", total_students: {}, id: 61 },
  { name: "Djibouti", code: "DJ", total_students: {}, id: 62 },
  { name: "Dominica", code: "DM", total_students: {}, id: 63 },
  { name: "Dominican Republic", code: "DO", total_students: {}, id: 64 },
  { name: "Ecuador", code: "EC", total_students: {}, id: 65 },
  { name: "Egypt", code: "EG", total_students: {}, id: 66 },
  { name: "El Salvador", code: "SV", total_students: {}, id: 67 },
  { name: "Equatorial Guinea", code: "GQ", total_students: {}, id: 68 },
  { name: "Eritrea", code: "ER", total_students: {}, id: 69 },
  { name: "Estonia", code: "EE", total_students: {}, id: 70 },
  { name: "Ethiopia", code: "ET", total_students: {}, id: 71 },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    total_students: {},
    id: 72,
  },
  { name: "Faroe Islands", code: "FO", total_students: {}, id: 73 },
  { name: "Fiji", code: "FJ", total_students: {}, id: 74 },
  { name: "Finland", code: "FI", total_students: {}, id: 75 },
  { name: "France", code: "FR", total_students: {}, id: 76 },
  { name: "French Guiana", code: "GF", total_students: {}, id: 77 },
  { name: "French Polynesia", code: "PF", total_students: {}, id: 78 },
  {
    name: "French Southern Territories",
    code: "TF",
    total_students: {},
    id: 79,
  },
  { name: "Gabon", code: "GA", total_students: {}, id: 80 },
  { name: "Gambia", code: "GM", total_students: {}, id: 81 },
  { name: "Georgia", code: "GE", total_students: {}, id: 82 },
  { name: "Germany", code: "DE", total_students: {}, id: 83 },
  { name: "Ghana", code: "GH", total_students: {}, id: 84 },
  { name: "Gibraltar", code: "GI", total_students: {}, id: 85 },
  { name: "Greece", code: "GR", total_students: {}, id: 86 },
  { name: "Greenland", code: "GL", total_students: {}, id: 87 },
  { name: "Grenada", code: "GD", total_students: {}, id: 88 },
  { name: "Guadeloupe", code: "GP", total_students: {}, id: 89 },
  { name: "Guam", code: "GU", total_students: {}, id: 90 },
  { name: "Guatemala", code: "GT", total_students: {}, id: 91 },
  { name: "Guernsey", code: "GG", total_students: {}, id: 92 },
  { name: "Guinea", code: "GN", total_students: {}, id: 93 },
  { name: "Guinea-Bissau", code: "GW", total_students: {}, id: 94 },
  { name: "Guyana", code: "GY", total_students: {}, id: 95 },
  { name: "Haiti", code: "HT", total_students: {}, id: 96 },
  {
    name: "Heard Island and Mcdonald Islands",
    code: "HM",
    total_students: {},
    id: 97,
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
    total_students: {},
    id: 98,
  },
  { name: "Honduras", code: "HN", total_students: {}, id: 99 },
  { name: "Hong Kong", code: "HK", total_students: {}, id: 100 },
  { name: "Hungary", code: "HU", total_students: {}, id: 101 },
  { name: "Iceland", code: "IS", total_students: {}, id: 102 },
  { name: "India", code: "IN", total_students: {}, id: 103 },
  { name: "Indonesia", code: "ID", total_students: {}, id: 104 },
  {
    name: "Iran, Islamic Republic of",
    code: "IR",
    total_students: {},
    id: 105,
  },
  { name: "Iraq", code: "IQ", total_students: {}, id: 106 },
  { name: "Ireland", code: "IE", total_students: {}, id: 107 },
  { name: "Isle of Man", code: "IM", total_students: {}, id: 108 },
  { name: "Israel", code: "IL", total_students: {}, id: 109 },
  { name: "Italy", code: "IT", total_students: {}, id: 110 },
  { name: "Jamaica", code: "JM", total_students: {}, id: 111 },
  { name: "Japan", code: "JP", total_students: {}, id: 112 },
  { name: "Jersey", code: "JE", total_students: {}, id: 113 },
  { name: "Jordan", code: "JO", total_students: {}, id: 114 },
  { name: "Kazakhstan", code: "KZ", total_students: {}, id: 115 },
  { name: "Kenya", code: "KE", total_students: {}, id: 116 },
  { name: "Kiribati", code: "KI", total_students: {}, id: 117 },
  {
    name: "Korea, Democratic People's Republic of",
    code: "KP",
    total_students: {},
    id: 118,
  },
  { name: "Korea, Republic of", code: "KR", total_students: {}, id: 119 },
  { name: "Kosovo", code: "XK", total_students: {}, id: 120 },
  { name: "Kuwait", code: "KW", total_students: {}, id: 121 },
  { name: "Kyrgyzstan", code: "KG", total_students: {}, id: 122 },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
    total_students: {},
    id: 123,
  },
  { name: "Latvia", code: "LV", total_students: {}, id: 124 },
  { name: "Lebanon", code: "LB", total_students: {}, id: 125 },
  { name: "Lesotho", code: "LS", total_students: {}, id: 126 },
  { name: "Liberia", code: "LR", total_students: {}, id: 127 },
  { name: "Libyan Arab Jamahiriya", code: "LY", total_students: {}, id: 128 },
  { name: "Liechtenstein", code: "LI", total_students: {}, id: 129 },
  { name: "Lithuania", code: "LT", total_students: {}, id: 130 },
  { name: "Luxembourg", code: "LU", total_students: {}, id: 131 },
  { name: "Macao", code: "MO", total_students: {}, id: 132 },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
    total_students: {},
    id: 133,
  },
  { name: "Madagascar", code: "MG", total_students: {}, id: 134 },
  { name: "Malawi", code: "MW", total_students: {}, id: 135 },
  { name: "Malaysia", code: "MY", total_students: {}, id: 136 },
  { name: "Maldives", code: "MV", total_students: {}, id: 137 },
  { name: "Mali", code: "ML", total_students: {}, id: 138 },
  { name: "Malta", code: "MT", total_students: {}, id: 139 },
  { name: "Marshall Islands", code: "MH", total_students: {}, id: 140 },
  { name: "Martinique", code: "MQ", total_students: {}, id: 141 },
  { name: "Mauritania", code: "MR", total_students: {}, id: 142 },
  { name: "Mauritius", code: "MU", total_students: {}, id: 143 },
  { name: "Mayotte", code: "YT", total_students: {}, id: 144 },
  { name: "Mexico", code: "MX", total_students: {}, id: 145 },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
    total_students: {},
    id: 146,
  },
  { name: "Moldova, Republic of", code: "MD", total_students: {}, id: 147 },
  { name: "Monaco", code: "MC", total_students: {}, id: 148 },
  { name: "Mongolia", code: "MN", total_students: {}, id: 149 },
  { name: "Montenegro", code: "ME", total_students: {}, id: 150 },
  { name: "Montserrat", code: "MS", total_students: {}, id: 151 },
  { name: "Morocco", code: "MA", total_students: {}, id: 152 },
  { name: "Mozambique", code: "MZ", total_students: {}, id: 153 },
  { name: "Myanmar", code: "MM", total_students: {}, id: 154 },
  { name: "Namibia", code: "NA", total_students: {}, id: 155 },
  { name: "Nauru", code: "NR", total_students: {}, id: 156 },
  { name: "Nepal", code: "NP", total_students: {}, id: 157 },
  { name: "Netherlands", code: "NL", total_students: {}, id: 158 },
  { name: "Netherlands Antilles", code: "AN", total_students: {}, id: 159 },
  { name: "New Caledonia", code: "NC", total_students: {}, id: 160 },
  { name: "New Zealand", code: "NZ", total_students: {}, id: 161 },
  { name: "Nicaragua", code: "NI", total_students: {}, id: 162 },
  { name: "Niger", code: "NE", total_students: {}, id: 163 },
  { name: "Nigeria", code: "NG", total_students: {}, id: 164 },
  { name: "Niue", code: "NU", total_students: {}, id: 165 },
  { name: "Norfolk Island", code: "NF", total_students: {}, id: 166 },
  { name: "Northern Mariana Islands", code: "MP", total_students: {}, id: 167 },
  { name: "Norway", code: "NO", total_students: {}, id: 168 },
  { name: "Oman", code: "OM", total_students: {}, id: 169 },
  { name: "Pakistan", code: "PK", total_students: {}, id: 170 },
  { name: "Palau", code: "PW", total_students: {}, id: 171 },
  {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    total_students: {},
    id: 172,
  },
  { name: "Panama", code: "PA", total_students: {}, id: 173 },
  { name: "Papua New Guinea", code: "PG", total_students: {}, id: 174 },
  { name: "Paraguay", code: "PY", total_students: {}, id: 175 },
  { name: "Peru", code: "PE", total_students: {}, id: 176 },
  { name: "Philippines", code: "PH", total_students: {}, id: 177 },
  { name: "Pitcairn", code: "PN", total_students: {}, id: 178 },
  { name: "Poland", code: "PL", total_students: {}, id: 179 },
  { name: "Portugal", code: "PT", total_students: {}, id: 180 },
  { name: "Puerto Rico", code: "PR", total_students: {}, id: 181 },
  { name: "Qatar", code: "QA", total_students: {}, id: 182 },
  { name: "Reunion", code: "RE", total_students: {}, id: 183 },
  { name: "Romania", code: "RO", total_students: {}, id: 184 },
  { name: "Russian Federation", code: "RU", total_students: {}, id: 185 },
  { name: "Rwanda", code: "RW", total_students: {}, id: 186 },
  { name: "Saint Barthelemy", code: "BL", total_students: {}, id: 187 },
  { name: "Saint Helena", code: "SH", total_students: {}, id: 188 },
  { name: "Saint Kitts and Nevis", code: "KN", total_students: {}, id: 189 },
  { name: "Saint Lucia", code: "LC", total_students: {}, id: 190 },
  { name: "Saint Martin", code: "MF", total_students: {}, id: 191 },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    total_students: {},
    id: 192,
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    total_students: {},
    id: 193,
  },
  { name: "Samoa", code: "WS", total_students: {}, id: 194 },
  { name: "San Marino", code: "SM", total_students: {}, id: 195 },
  { name: "Sao Tome and Principe", code: "ST", total_students: {}, id: 196 },
  { name: "Saudi Arabia", code: "SA", total_students: {}, id: 197 },
  { name: "Senegal", code: "SN", total_students: {}, id: 198 },
  { name: "Serbia", code: "RS", total_students: {}, id: 199 },
  { name: "Serbia and Montenegro", code: "CS", total_students: {}, id: 200 },
  { name: "Seychelles", code: "SC", total_students: {}, id: 201 },
  { name: "Sierra Leone", code: "SL", total_students: {}, id: 202 },
  { name: "Singapore", code: "SG", total_students: {}, id: 203 },
  { name: "Sint Maarten", code: "SX", total_students: {}, id: 204 },
  { name: "Slovakia", code: "SK", total_students: {}, id: 205 },
  { name: "Slovenia", code: "SI", total_students: {}, id: 206 },
  { name: "Solomon Islands", code: "SB", total_students: {}, id: 207 },
  { name: "Somalia", code: "SO", total_students: {}, id: 208 },
  { name: "South Africa", code: "ZA", total_students: {}, id: 209 },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    total_students: {},
    id: 210,
  },
  { name: "South Sudan", code: "SS", total_students: {}, id: 211 },
  { name: "Spain", code: "ES", total_students: {}, id: 212 },
  { name: "Sri Lanka", code: "LK", total_students: {}, id: 213 },
  { name: "Sudan", code: "SD", total_students: {}, id: 214 },
  { name: "Suriname", code: "SR", total_students: {}, id: 215 },
  { name: "Svalbard and Jan Mayen", code: "SJ", total_students: {}, id: 216 },
  { name: "Swaziland", code: "SZ", total_students: {}, id: 217 },
  { name: "Sweden", code: "SE", total_students: {}, id: 218 },
  { name: "Switzerland", code: "CH", total_students: {}, id: 219 },
  { name: "Syrian Arab Republic", code: "SY", total_students: {}, id: 220 },
  {
    name: "Taiwan, Province of China",
    code: "TW",
    total_students: {},
    id: 221,
  },
  { name: "Tajikistan", code: "TJ", total_students: {}, id: 222 },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
    total_students: {},
    id: 223,
  },
  { name: "Thailand", code: "TH", total_students: {}, id: 224 },
  { name: "Timor-Leste", code: "TL", total_students: {}, id: 225 },
  { name: "Togo", code: "TG", total_students: {}, id: 226 },
  { name: "Tokelau", code: "TK", total_students: {}, id: 227 },
  { name: "Tonga", code: "TO", total_students: {}, id: 228 },
  { name: "Trinidad and Tobago", code: "TT", total_students: {}, id: 229 },
  { name: "Tunisia", code: "TN", total_students: {}, id: 230 },
  { name: "Turkey", code: "TR", total_students: {}, id: 231 },
  { name: "Turkmenistan", code: "TM", total_students: {}, id: 232 },
  { name: "Turks and Caicos Islands", code: "TC", total_students: {}, id: 233 },
  { name: "Tuvalu", code: "TV", total_students: {}, id: 234 },
  { name: "Uganda", code: "UG", total_students: {}, id: 235 },
  { name: "Ukraine", code: "UA", total_students: {}, id: 236 },
  { name: "United Arab Emirates", code: "AE", total_students: {}, id: 237 },
  { name: "United Kingdom", code: "GB", total_students: {}, id: 238 },
  { name: "United States", code: "US", total_students: {}, id: 239 },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    total_students: {},
    id: 240,
  },
  { name: "Uruguay", code: "UY", total_students: {}, id: 241 },
  { name: "Uzbekistan", code: "UZ", total_students: {}, id: 242 },
  { name: "Vanuatu", code: "VU", total_students: {}, id: 243 },
  { name: "Venezuela", code: "VE", total_students: { PIAR: 1 }, id: 244 },
  { name: "Viet Nam", code: "VN", total_students: {}, id: 245 },
  { name: "Virgin Islands, British", code: "VG", total_students: {}, id: 246 },
  { name: "Virgin Islands, U.s.", code: "VI", total_students: {}, id: 247 },
  { name: "Wallis and Futuna", code: "WF", total_students: {}, id: 248 },
  { name: "Western Sahara", code: "EH", total_students: {}, id: 249 },
  { name: "Yemen", code: "YE", total_students: {}, id: 250 },
  { name: "Zambia", code: "ZM", total_students: {}, id: 251 },
  { name: "Zimbabwe", code: "ZW", total_students: {}, id: 252 },
];
