import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
  import { useGetList } from "react-admin";
import lodash from 'lodash';
import { PROGRAM_TYPES } from '../../../../constants/common';

const ProgramTypes = ({resource, ...props}) => {
      const { data, ids, loading, error } = useGetList(
    resource,
    { page: 1, perPage: 1000 },
    {}
  );
  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error loading data...</p>;
  }

  const programs = lodash.keys(data).map((key) => data[key]);
  const types = lodash.groupBy(programs, (it) => it.program_type);

  const chartData = [];

  lodash.keys(PROGRAM_TYPES).forEach((key) => {
    chartData.push({
        name: key,
        count: types[key] ? types[key].length : 0
    })
  })
    return (
        <Card
            style={{ height: '400px' }}
            {...props}
            >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    style={{ 
                        justifyContent: 'space-around',
                        alignItems: "center" ,
                        height: '400px', 
                        padding: '15px'
                    }}
                >
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={chartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <XAxis dataKey="name" />
                        {/* <YAxis /> */}
                        <Tooltip />
                        <Bar dataKey="count" fill='#036eac' />
                        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                        </BarChart>
                    </ResponsiveContainer>
                    
                </Grid>
            </CardContent>
        </Card>
    );
  }

export default ProgramTypes
