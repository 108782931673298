import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { TextField, Divider, Typography } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import base64 from "base-64";

function RegistrationLink({ record, ...props }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: 35,
      }}
    >
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <Typography variant="h6">Student registration link:</Typography>
        <Typography variant="h6">
          {`${window.location.origin}/#/registration/${base64.encode(
            JSON.stringify({
              program_id: record.id,
            })
          )}`}
        </Typography>
      </div>

      {/* <TextField
        disabled
        style={{ width: "45%" }}
        variant="outlined"
        value={`${window.location.origin}/#/registration/${base64.encode(
          JSON.stringify({
            program_id: record.id,
          })
        )}`}
      ></TextField> */}
      <CopyToClipboard
        text={`${window.location.origin}/#/registration/${base64.encode(
          JSON.stringify({
            program_id: record.id,
          })
        )}`}
      >
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <IconButton>
            <FileCopyIcon style={{ marginLeft: 5 }} />
          </IconButton>
        </div>
      </CopyToClipboard>
    </div>
  );
}

export default RegistrationLink;
