import { AppBar, Badge, Hidden, IconButton, Toolbar } from "@material-ui/core";
import React, { useState } from "react";

import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { userLogout } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    background: "white",
    borderBottom: "1px solid #eeeeee",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, userLogout, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  const handleSignOut = () => {
    userLogout();
    window.location = "#/login";
  };

  const handlePendingEnrollments = () => {
    window.location =
      '#/student-enrollments?filter={"status": "pre_admission"}';
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="logo" src="/images/logos/logo-default.png" width={200} />
        </RouterLink>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon style={{ color: "#036eac" }} />
          </IconButton>
        </Hidden>
        <div className={classes.flexGrow} />
        {/* <Hidden mdDown> */}
        <IconButton color="primary" onClick={handlePendingEnrollments}>
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon style={{ color: "#036eac" }} />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          color="primary"
          onClick={handleSignOut}
        >
          <InputIcon style={{ color: "#036eac" }} />
        </IconButton>
        
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default connect(undefined, { userLogout })(Topbar);
