import { Edit, PasswordInput, SimpleForm, TextInput, email, required } from 'react-admin';

import React from 'react';

const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
        <TextInput
        source="email"
        fullWidth
        variant="outlined"
        validate={[required(), email()]}
      />
      <TextInput
        source="first_name"
        fullWidth
        variant="outlined"
        validate={[required()]}
      />
      <TextInput
        source="last_name"
        fullWidth
        variant="outlined"
        validate={[required()]}
      />
      <TextInput
        source="username"
        fullWidth
        variant="outlined"
        validate={[required()]}
      />
      <PasswordInput
        source="password"
        fullWidth
        variant="outlined"
        validate={[required()]}
      />
        </SimpleForm>
    </Edit>
);

export default UserEdit;
