import OrganizationList from './OrganizationList';
import OrganizationEdit from './OrganizationEdit';
import OrganizationShow from './OrganizationShow';
import OrganizationCreate from './OrganizationCreate';

export default {
    list: OrganizationList,
    edit: OrganizationEdit,
    show: OrganizationShow,
    create: OrganizationCreate
};
