import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import { Admin, ListGuesser, Resource, ShowGuesser , cacheDataProviderProxy} from 'react-admin';
import React, { Component } from 'react';
import { authProvider, dataProvider, uploaderWrapper } from './data/providers';

import { API_URL } from './constants/config';
import Countries from './views/pages/Countries';
import { Main } from './ui/layouts';
import Organizations from './views/pages/Organizations';
import Programs from './views/pages/Programs';
import { SignIn } from './views/pages';
import StudentEnrollments from './views/pages/StudentEnrollments';
import Students from './views/pages/Students';
import { ThemeProvider } from '@material-ui/styles';
import Users from './views/pages/Users';
import customRoutes from './customRoutes';
import i18nProvider from './data/providers/i18nProvider';
import theme from './ui/theme';
import Reports from './views/pages/Reports';
import Dashboard from './views/pages/Dashboard';

export const DataProvider = cacheDataProviderProxy(dataProvider(API_URL));

export default class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Admin
                    title='AMS'
                    dataProvider={DataProvider}
                    authProvider={authProvider}
                    i18nProvider={i18nProvider}
                    layout={Main}
                    loginPage={SignIn}
                    customRoutes={customRoutes}
                    dashboard={Dashboard}
                >
                    <Resource name='students' {...Students} />
                    <Resource name='media' show={ShowGuesser} list={ListGuesser} />
                    <Resource name='countries' {...Countries} />
                    <Resource name='programs' {...Programs} />
                    <Resource name='organizations' {...Organizations} />
                    <Resource name='student-enrollments' {...StudentEnrollments} />
                    <Resource name='users' {...Users} />
                    <Resource name='reports' {...Reports} />
                </Admin>
            </ThemeProvider>
        );
    }
}
