import Box from '@material-ui/core/Box';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const Empty = (props) => {
    const { loading, loaded, total } = props;
    if (total === 0 && !loading && loaded) {
        return (
            <Box textAlign='center' m={1}>
                <Typography variant='h4' paragraph>
                    No data available for this report
                </Typography>
            </Box>
        );
    }

    return <div></div>;
};
export default Empty;
