import {
  DateInput,
  Edit,
  SelectInput,
  FormTab,
  TabbedForm,
  TextInput,
} from "react-admin";
import {
  PROGRAM_STATUS,
  PROGRAM_STREAM,
  PROGRAM_TYPES,
} from "../../../constants/common";

import React from "react";
import { generateChoices } from "../../../helpers";
// import CustomTextArea from "./CustomTextArea";
import RichTextInput from "ra-input-rich-text";

import { makeStyles } from "@material-ui/styles";
import CustomTextArea from "./CustomTextArea";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: 250,
  },
  input: {
    height: 200,
  },
});

const ProgramEdit = (props) => {
  const classes = useStyles();
  
  return (
    <Edit {...props}>
      <TabbedForm >
        <FormTab label="Summary">
          <Box display={{ md: "block", lg: "flex" }} style={{ width: '90%'}}>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <TextInput disabled source="id" variant="outlined" fullWidth/>
              <SelectInput
                source="status"
                variant="outlined"
                fullWidth
                choices={generateChoices(PROGRAM_STATUS)}
              />
              <SelectInput
                source="stream"
                fullWidth
                variant="outlined"
                choices={generateChoices(PROGRAM_STREAM)}
              />
            </Box>
            <Box flex={3} mr={{ md: 0, lg: "1em" }}>
              <TextInput source="name" variant="outlined" fullWidth />
              <SelectInput
              fullWidth
                source="program_type"
                variant="outlined"
                choices={generateChoices(PROGRAM_TYPES)}
              />
              
            </Box>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <DateInput source="start_date" variant="outlined" />
              <DateInput source="end_date" variant="outlined" />
            </Box>
          </Box>
        </FormTab>
        <FormTab label="Email Template">
          <CustomTextArea source="email_template" {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
export default ProgramEdit;
