import { Datagrid, FunctionField, List, TextField, TopToolbar, sanitizeListRestProps } from 'react-admin';

import Empty from './Empty';
import { ExportPdfButton } from './ReportActions';
import React from 'react';
import { STATIC_URL } from '../../../constants/config';
import { useSelector } from "react-redux";

const CountryActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => {
    const getCountryContent = () => {
        const countryList = document.getElementById('country-list');
        return countryList ? countryList.outerHTML : null;
    };
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <ExportPdfButton getContent={getCountryContent} exportTitle="Country Report" />
        </TopToolbar>
    );
};

const Flag = ({ source, record = {} }) => {
    const flagUrl = record.name.replace(/ /g, '_');
    return (
        <div>
            <img src={`${STATIC_URL}/flags/${flagUrl}.png`} width='100' alt={record.name} />
        </div>
    );
};

const renderTotalStudents = (record) => {
    let items = Object.entries(record.total_students);
    return items.map((item) => `${item[0]}: ${item[1]}`);
};

export const Country = (props) => {
    const { staticContext, ...rest } = props;
    const {loading} = useSelector((state) => state.admin);

    if (loading) return null;

    return (
        <List
            perPage={1000}
            bulkActionButtons={false}
            filter={{ total_students: true }}
            pagination={<Empty />}
            actions={<CountryActions />}
            {...rest}
        >
            <Datagrid id='country-list'>
                <Flag />
                <TextField source='name' label='Country' />
                <FunctionField
                    label='Total Alumni'
                    sortable={false}
                    render={(record) => Object.entries(record.total_students).length}
                />
                <FunctionField
                    source='total_students'
                    label='Alumni by Countries'
                    sortable={false}
                    render={renderTotalStudents}
                />
            </Datagrid>
        </List>
    );
};
