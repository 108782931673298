// import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import { USER_INFO, TOKEN } from '../../constants/auth';
import JWT from "jwt-decode";

import { API_URL } from '../../constants/config';

export default {
    login: ({ username, password }) => {
        const request = new Request(`${API_URL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then(response => {
                if (response.status === 401) {
                    throw new Error('Incorrect username and password.');
                }
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token }) => {
                if (access_token) {
                    var decoded = JWT(access_token);
                    debugger
                    localStorage.setItem(TOKEN, access_token);
                    localStorage.setItem(USER_INFO, JSON.stringify(decoded.identity));
                } else {
                    throw new Error('Incorrect username and password.');
                }
            });
    },
    logout: () => {
        localStorage.removeItem(TOKEN);
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem(TOKEN)
        ? Promise.resolve()
        : Promise.reject({ redirectTo: '/no-access' }),
    getPermissions: () => Promise.reject('Unknown method')
};
