import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const CountryShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source='code' />
            <TextField source='name' />
        </SimpleShowLayout>
    </Show>
);

export default CountryShow;
