import React from 'react';
import {List, Datagrid, TextField, ReferenceField, Edit, SimpleForm, ReferenceInput, Create, TextInput, NumberInput, SelectInput, Show, SimpleShowLayout, Filter} from 'react-admin';

const OrganizationShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceField source="country_id" reference="countries">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="country.code" />
            <TextField source="address" />
        </SimpleShowLayout>
    </Show>
);

export default OrganizationShow;
