export default {
	users: [
	  {
		id: 1,
		name: 'Peter',
		email: 'perter@gmail.com',
		first_name: 'Peter',
		last_name: 'Nikolaev',
		home_phone: '+7123456789',
		mobile_phone: '+7123456789',
		avatar: 'peter.png',
		relationship: 'Father',
	  },
	]
};
  