import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Edit,
  SimpleForm,
  ReferenceInput,
  Create,
  TextInput,
  NumberInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  Filter,
  required
} from "react-admin";

const OrganizationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="country_id"
        reference="countries"
        fullWidth
        variant="outlined"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="name"
        fullWidth
        variant="outlined"
        validate={[required()]}
      />
      <TextInput
        source="address"
        fullWidth
        variant="outlined"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

export default OrganizationCreate;
