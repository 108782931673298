import {
  Create,
  DateInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  FormTab,
  TabbedForm,
  FormDataConsumer,
} from "react-admin";
import {
  PROGRAM_STATUS,
  PROGRAM_STREAM,
  PROGRAM_TYPES,
} from "../../../constants/common";

import React from "react";
import { generateChoices } from "../../../helpers";
import { Box } from "@material-ui/core";
import CustomTextArea from "./CustomTextArea";
import lodash from 'lodash';

const ProgramCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm redirect="show">
        <FormTab label="Summary">
          <Box display={{ md: "block", lg: "flex" }} style={{ width: "90%" }}>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  formData.status = 'draft';
                  
                  return (
                    <SelectInput
                      source="status"
                      disabled
                      variant="outlined"
                      fullWidth
                      choices={generateChoices(PROGRAM_STATUS)}
                    />
                  );
                }}
              </FormDataConsumer>
              <SelectInput
                source="stream"
                fullWidth
                variant="outlined"
                choices={generateChoices(PROGRAM_STREAM)}
              />
            </Box>
            <Box flex={3} mr={{ md: 0, lg: "1em" }}>
              <TextInput source="name" variant="outlined" fullWidth />
              <SelectInput
                fullWidth
                source="program_type"
                variant="outlined"
                choices={generateChoices(PROGRAM_TYPES)}
              />
            </Box>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <DateInput source="start_date" variant="outlined" />
              <DateInput source="end_date" variant="outlined" />
            </Box>
          </Box>
        </FormTab>
        <FormTab label="Email template">
          <CustomTextArea source="email_template" {...props} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
export default ProgramCreate;
