import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        position: 'fixed',
        width: '100%',
        backgroundColor: '#f4f6f8',
        bottom: 0,
    },
}));

const Footer = (props) => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Typography variant='body1'>
                &copy; <Link component='a' href='#' target='_blank'></Link>
            </Typography>
            <Typography variant='caption'></Typography>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
