import { Button, useNotify, useRedirect, useUpdate } from 'react-admin';

import DoneIcon from '@material-ui/icons/Done';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { Link } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import React from 'react';
import { stringify } from 'query-string';

export const StudentsButton = ({ record }) => (
    <Button
        label='Students'
        component={Link}
        to={{
            pathname: '/student-enrollments',
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ program_id: record.id }),
            }),
        }}
    >
        <PeopleIcon />
    </Button>
);

export const ProgramPublishButton = ({ record }) =>
    record.status  === 'draft' && (
        <ActionButton label='Publish' record={record} status='published' icon={<PlayIcon />} />
    );

export const ProgramStartButton = ({ record }) =>
    record.status === 'published' && (
        <ActionButton label='Start' record={record} status='started' icon={<PlayIcon />} />
    );

export const ProgramCompleteButton = ({ record }) =>
    record.status === 'started' && (
        <ActionButton label='Complete' record={record} status='completed' icon={<DoneIcon />} />
    );

export const ProgramRoasterButton = ({ record }) => (
    <Button
        label='Roster'
        component={Link}
        to={{
            pathname: `/report-roster/${record.id}`,
        }}
    >
        <FormatListBulletedIcon />
    </Button>
);

export const ProgramAlumniButton = ({ record }) => (
    <Button
        label='Alumni'
        component={Link}
        to={{
            pathname: `/report-alumni/${record.id}`,
        }}
    >
        <FormatListBulletedIcon />
    </Button>
);

export const ProgramGradesButton = ({ record }) => (
    <Button
        label='Grades'
        component={Link}
        to={{
            pathname: `/report-grades/${record.id}`,
        }}
    >
        <FormatListBulletedIcon />
    </Button>
);



const ActionButton = ({ record, status, label, icon }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [approve, { loading }] = useUpdate(
        'programs',
        record.id,
        { status: status },
        {},
        {
            onSuccess: ({ data }) => {
                redirect('/programs/' + record.id + '/show');
                notify('Program has been started', 'info');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return (
        <Button label={label} onClick={approve} disabled={loading}>
            {icon}
        </Button>
    );
};
