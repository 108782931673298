import { useNotify , useRefresh} from "react-admin";
import React, { useState, useEffect } from "react";
import { DeleteOutline } from "@material-ui/icons";
import lodash from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import useDeleteMedia from "../../../data/providers/useDeleteMedia";
import useUploadMedia from "../../../data/providers/useUploadMedia";

const useStyles = makeStyles(
  (theme) => ({
    dropZone: {
      background: theme.palette.background.default,
      cursor: "pointer",
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {},
    removeButton: {},
    root: { width: "100%" },
    deleteIcon: {
      cursor: "pointer",
      marginRight: 10,
    },
    fileItem: {
      lineHeight: "35px",
      display: "flex",
      alignItems: "center",
    },
  }),
  { name: "RaFileInput" }
);

const FileUploader = ({
  record,
  resource,
  accept,
  maxSize,
  minSize,
  multiple,
  tag,
  label,
  ...props
}) => {
  const [files, setFiles] = useState([]);
  const [media, setMedia] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const classes = useStyles(props);
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    const propsFiles = lodash
      .sortBy(
        record.media &&
          record.media.filter(
            (item) =>
              JSON.parse(item.meta) &&
              JSON.parse(item.meta).tag &&
              JSON.parse(item.meta).tag === tag
          ),
        "id"
      )
      .reverse();

    setFiles(multiple ? propsFiles : [propsFiles[0]]);
  }, [record && record.media]);

  useEffect(() => {
    if (media) uploadMedia();
  }, [media]);

  useEffect(() => {
    if (deleteFile) deleteMedia();
  }, [deleteFile]);

  const [uploadMedia, { loading }] = useUploadMedia(media, {
    onSuccess: (response) => {
      notify("loaded", "success");
      refresh();
    },
    onFailure: ({ error }) => {
      notify(error.message, "error");
      refresh();
    },
  });
  const [deleteMedia] = useDeleteMedia(deleteFile, {
    onSuccess: (response) => {
      const propsFiles = lodash
        .sortBy(
          record.media &&
            record.media.filter(
              (item) =>
                JSON.parse(item.meta) &&
                JSON.parse(item.meta).tag &&
                JSON.parse(item.meta).tag === tag
            ),
          "id"
        )
        .reverse()[1];
      notify("deleted", "success");

      setFiles([propsFiles]);
      refresh();
    },
    onFailure: ({ error }) => {
      notify(error.message, "error");
      refresh();
    },
  });

  const removeMedia = (id) => (event) => {
    setDeleteFile({ id, record, entity_type: resource });
  };

  const transformFile = (file) => {
    if (!(file instanceof File)) {
      return file;
    }

    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      link: preview,
      title: file.name,
    };

    return transformedFile;
  };

  const transformFiles = (files) => {
    if (!files) {
      return props.multiple ? [] : null;
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return transformFile(files);
  };

  const onDrop = (newFiles, rejectedFiles, event) => {
    const formattedFiles = transformFiles(newFiles);

    if (multiple) {
      setFiles([...files, ...formattedFiles]);
      setMedia(
        formattedFiles.map((file) => ({
          entity_id: record.id,
          entity_type: resource,
          media: file.rawFile,
          title: file.title,
          meta: JSON.stringify({ tag }),
          record: record,
        }))
      );
    } else {
      setFiles([formattedFiles[0]]);
      setMedia({
        entity_id: record.id,
        entity_type: resource,
        media: formattedFiles[0].rawFile,
        title: formattedFiles[0].title,
        meta: JSON.stringify({ tag }),
        record: record,
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  });

  return (
    <div>
      <div
        data-testid="dropzone"
        className={classes.dropZone}
        {...getRootProps()}
      >
        <input
          {...getInputProps({
            ...props,
          })}
        />
        {props.placeholder}
      </div>
      {files &&
        files.map((item) =>
          item ? (
            <div className={classes.fileItem}>
              <DeleteOutline
                className={classes.deleteIcon}
                onClick={removeMedia(item.id)}
              />
              <a href={item.link} target="_blank">
                {item.title}
              </a>
            </div>
          ) : null
        )}
    </div>
  );
};

export default FileUploader;