import React from "react";
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { useGetList } from "react-admin";
import lodash from "lodash";
import Enrollments from "./components/Enrollments";
import CounterWidget from "./components/CounterWidget";
import ProgramTypes from "./components/ProgramTypes";

const ENROLLMENTS_TYPE = {
  PRE_ADMISSION: "Pre-Admission",
  ADMITTED: "Admitted",
  FUNDING_CONFIRMED: "Funding Confirmed",
  GRADUATED: "Graduated",
  REJECTED: "Rejected",
};

const useStyles = makeStyles({
  root: {
    padding: 25,
  },
  headStat: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  counter: {
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    height: 200,
    border: "1px solid #d4d4d4",
    padding: 20,
    textAlign: "center",
  },
  counterValue: {
    fontSize: 80,
  },
  counterTitle: {
    textTransform: "uppercase",
  },
  statbox: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    border: "1px solid #d4d4d4",
    padding: 20,
    marginTop: 20,
  },
});

// function Counter({ classes, resource, ...props }) {
//   const { data, ids, loading, error } = useGetList(
//     resource,
//     { page: 1, perPage: 1000 },
//     {}
//   );

//   if (loading) {
//     return <p>Loading...</p>;
//   }
//   if (error) {
//     return <p>ERROR</p>;
//   }

//   return (
//     <Card className={classes.counter}>
//       <CardHeader title={resource} className={classes.counterTitle} />
//       <CardContent>
//         <Typography variant="h1" className={classes.counterValue}>
//           {ids.length}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// }

// function ProgramTypes({ classes, resource, ...props }) {
//   const { data, ids, loading, error } = useGetList(
//     resource,
//     { page: 1, perPage: 1000 },
//     {}
//   );
//   console.log(data);
//   if (loading) {
//     return <p>Loading...</p>;
//   }
//   if (error) {
//     return <p>ERROR</p>;
//   }

//   const programms = lodash.keys(data).map((key) => data[key]);
//   const types = lodash.groupBy(programms, (it) => it.program_type);

//   return (
//     <Card  className={classes.statbox}>
//       <CardHeader title={"Program Types"} className={classes.counterTitle} />
//       <CardContent>
//         {lodash.keys(types).map((key) => (
//           <Typography variant="h2" className={classes.counterTitle}>
//             {`${key}:  ${types[key].length}`}
//           </Typography>
//         ))}
//       </CardContent>
//     </Card>
//   );
// }

function Dashboard(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <CounterWidget resource="countries" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <CounterWidget resource="organizations" />
            {/* <TotalCustomers /> */}
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <CounterWidget resource="programs" />
            {/* <TasksProgress /> */}
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <CounterWidget resource="students"/>

            {/* <TotalProfit sx={{ height: '100%' }} /> */}
          </Grid>
          <Grid
            item
            lg={5}
            md={12}
            xl={9}
            xs={12}
          >
            <ProgramTypes resource="programs" />
            {/* <Sales /> */}
          </Grid>
          <Grid
            item
            lg={7}
            md={12}
            xl={9}
            xs={12}
          >
            <Enrollments />
            {/* <TrafficByDevice sx={{ height: '100%' }} /> */}
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            {/* <LatestProducts sx={{ height: '100%' }} /> */}
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            {/* <LatestOrders /> */}
            
          </Grid>
        </Grid>
      </Container>


      {/* <div className={classes.s}>
        <Box display={{ md: "block", lg: "flex" }}>
          <Box flex={1} mr={{ md: 0, lg: "1em" }}>
            <Counter classes={classes} resource="countries" />
          </Box>
          <Box flex={1} mr={{ md: 0, lg: "1em" }}>
            <Counter classes={classes} resource="organizations" />
          </Box>
          <Box flex={1} mr={{ md: 0, lg: "1em" }}>
            <Counter classes={classes} resource="programs" />
          </Box>
          <Box flex={1} mr={{ md: 0, lg: "1em" }}>
            <Counter classes={classes} resource="students" />
          </Box>
        </Box>
      </div> */}
      {/* <div className={classes.xx}>
        <Box display={{ md: "block", lg: "flex" }}>
          <Box flex={1} mr={{ md: 0, lg: "1em" }}>
            <ProgramTypes classes={classes} resource="programs" />
          </Box>
          <Box flex={1} mr={{ md: 0, lg: "1em" }}>
            <Enrollments />
          </Box>
        </Box>
      </div> */}
    </Box>
    
    </div>
  );
}

export default Dashboard;
