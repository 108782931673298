import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { useGetList } from "react-admin";
import lodash from "lodash";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Text,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ENROLLMENTS_TYPE = {
  PRE_ADMISSION: "Pre-Admission",
  ADMITTED: "Admitted",
  FUNDING_CONFIRMED: "Funding Confirmed",
  GRADUATED: "Graduated",
  REJECTED: "Rejected",
};

const useStyles = makeStyles({
  root: {
    padding: 25,
  },
  headStat: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  counter: {
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    height: 200,
    border: "1px solid #d4d4d4",
    padding: 20,
    textAlign: "center",
  },
  counterValue: {
    fontSize: 80,
  },
  counterTitle: {
    textTransform: "uppercase",
  },
  statbox: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    border: "1px solid #d4d4d4",
    padding: 20,
    marginTop: 20,
  },
});

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start" style={{ fontSize: 12}}>
      {payload.value}
    </Text>
  );
}

function Enrollments(props) {
  const classes = useStyles();
  const { data, ids, loading, error } = useGetList(
    "student-enrollments",
    { page: 1, perPage: 1000 },
    {},
    props.record ? { program_id: props.record.id } : {}
  );
  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error loading data...</p>;
  }

  const enrollments = lodash.keys(data).map((key) => data[key]);
  const status = lodash.groupBy(enrollments, (it) => it.status);
  const chartData = [];

  lodash.keys(ENROLLMENTS_TYPE).forEach((key) => {
    chartData.push({
      name: ENROLLMENTS_TYPE[key],
      count: status[key.toLowerCase()] ? status[key.toLowerCase()].length : 0,
    });
  });

  return (
    <Card style={{ height: "400px" }} {...props}>
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{
            justifyContent: "space-around",
            alignItems: "center",
            height: "400px",
            padding: "15px",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              {/* <XAxis dataKey="name" /> */}
              <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />}  />
              <Tooltip />
              <Bar dataKey="count" fill="#036eac" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Enrollments;
