// import React from "react";
// import lodash from "lodash";
// import CKEditor from "@ckeditor/ckeditor5-react";
import { addField, useInput } from "react-admin";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// const CustomTextArea = (props) => {
//   console.log(props);
//   const {
//     input: { onChange },
//     meta: { touched, error },
//   } = useInput(props);

//   return (
//     <div className="aor-ckeditor-input">
//       <CKEditor
//         editor={ClassicEditor}
//         config={{
//           allowedContent: true,
//         }}
//         data={
//           lodash.get(props.formData, props.source) ||
//           lodash.get(props.formValues, props.source) ||
//           lodash.get(props.record, props.source) ||
//           ""
//         }
//         onChange={(event, editor) => onChange(editor.getData())}
//         {...props}
//       />
//       {touched && error && (
//         <div style={{ color: "#f44336" }}>{error}</div>
//       )}
//     </div>
//   );
// };

// CustomTextArea.defaultProps = {
//   addField: true,
// };

// // export default addField(CustomTextArea);
// export default CustomTextArea;

import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CustomTextArea = (props) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useInput(props);
  return (
    <div className="App">
      <CKEditor
        config={{ height: "800px" }}
        editor={ClassicEditor}
        data={
          props.record
            ? props.record.email_template
            : "<p>Enter Email template for the Programm!</p>"
        }
        onChange={(event, editor) => onChange(editor.getData())}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
    </div>
  );
};

export default CustomTextArea;
