import englishMessages from 'ra-language-english';

const messages = {
    common: {
        avatar: {
            loadingPrimary: 'Loading',
            loadingSecondary: 'The avatar is loading, just a moment please',
        },
    },
    ...englishMessages,
    resources: {},
};

export default messages;
