import {
  Datagrid,
  Filter,
  List,
  ListBase,
  ReferenceInput,
  RichTextField,
  SelectInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext
} from "react-admin";
import { GetReference, parseQuery } from "../../../helpers";

import Avatar from "../../components/Avatar";
import Box from "@material-ui/core/Box";
import Empty from "./Empty";
import { ExportPdfButton } from "./ReportActions";
import { PROGRAM_TYPES } from "../../../constants/common";
import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { cloneElement } from "react";
import { generateChoices } from "../../../helpers";
import { isEmpty } from "lodash";
import { GoToProgramButton } from "../StudentEnrollments/StudentEnrollmentActions";
import { useSelector } from "react-redux";

const StudentGeneralField = ({ source, record = {} }) => (
  <div>
    <p>
      <b>{record.fullname}</b>
    </p>{" "}
    <br />
    <p>{record.organization}</p>
    <p>{record.position}</p>
    <p>{record.country_details && record.country_details.name}</p>
  </div>
);

const StudentEducationalSummary = ({ source, record = {} }) => (
  <div>
    <p>{record.email}</p>
    <p>{record.alternative_email}</p>
    <p>
      {record.mobile_phone}|{record.office_phone}|{record.home_phone}
    </p>
  </div>
);

const AlumniActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  program_id,
  total,
  ...rest
}) => {
  const getAlumniContent = () => {
    const rosterList = document.getElementById("alumni-list");
    return rosterList ? rosterList.outerHTML : null;
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      { program_id && <GoToProgramButton program_id={program_id} /> }
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportPdfButton getContent={getAlumniContent} exportTitle="Alumni Report" />
    </TopToolbar>
  );
};

const AlumniFilter = (props) => {
    const { setFilters } = useListContext();

    useEffect(() => {
        return () => {
            setFilters({})
        }
    }, [])

  return (
    <Filter {...props}>
      <SelectInput
        source="program_type"
        choices={generateChoices(PROGRAM_TYPES)}
        alwaysOn
        variant="outlined"
      />
      {GetReference("countries") && (
        <ReferenceInput
          alwaysOn
          allowEmpty={true}
          label="Country"
          source="country_id"
          reference="countries"
          perPage={1000}
          sort={'ASC'}
        >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export const Alumni = (props) => {
  const { location, staticContext, ...rest } = props;
  const params = parseQuery(location.search.substr(1).split("&"));
  const filter = params.filter ? JSON.parse(params.filter) : {};
  const { program_id } = props.match.params;
  const {loading} = useSelector((state) => state.admin);

  return filter.country_id && filter.program_type && !loading ? (
    <List
      perPage={1000}
      bulkActionButtons={false}
      pagination={<Empty />}
      actions={<AlumniActions program_id={program_id} />}
      filters={<AlumniFilter />}
      empty={<Empty />}
      {...rest}
    >
      <Datagrid id="alumni-list">
        <Avatar width={70} />
        <StudentGeneralField source="fullname" label="Summary" />
        <StudentEducationalSummary
          source="education_summary"
          label="Education Summary"
        />
      </Datagrid>
    </List>
  ) : (
    <List
      perPage={1000}
      bulkActionButtons={false}
      pagination={false}
      actions={<AlumniActions program_id={program_id}/>}
      filters={<AlumniFilter />}
      {...rest}
    >
      <ListBase>
        <h1>{props.title}</h1>
        <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            Please select Program and Country first.
          </Typography>
        </Box>
      </ListBase>
    </List>
  );
};
