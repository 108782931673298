// import { Create, DateInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

// import React from 'react';
// import { Toolbar } from '@material-ui/core';

import { Box, Card, CardContent, Typography } from "@material-ui/core";
import {
  DateInput,
  Edit,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
  Toolbar,
  useNotify,
  useMutation,
  email,
  number,
  SimpleForm,
  Create,
  required,
  FormDataConsumer,
  useRedirect,
} from "react-admin";
import { useFormState } from "react-final-form";
import { Link as RouterLink, useLocation } from "react-router-dom";

import Avatar from "../../components/Avatar";
import { FileUploader } from "../../components/FileUploader";
import { FormSpy } from "react-final-form";
import React, { useEffect, useState } from "react";
import { COUNTRIES } from "../../../constants/common";
import base64 from "base-64";

const createRedirect = (basePath, id, data) => `/registration-success`;

const StudentRegistration = (props) => {
  const [params, setParams] = useState({});
  const location = useLocation();
  const redirect = useRedirect();

  useEffect(() => {
    const params = location.pathname.split("/")[2];
    setParams(JSON.parse(base64.decode(params)));
  }, []);

  return (
    <Create {...props} basePath="/students" resource="students">
      <SimpleForm redirect={createRedirect}>
        <Box display={{ md: "block", lg: "flex" }} style={{ width: "100%" }}>
          <Box flex={2} mr={{ md: 0, lg: "1em" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                formData.program_id = params.program_id;

                return (
                  <Typography variant="h6" gutterBottom>
                    Personal Information
                  </Typography>
                );
              }}
            </FormDataConsumer>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="fullname"
                  resource="students"
                  fullWidth
                  variant="outlined"
                  validate={required()}
                />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  source="gender"
                  choices={[
                    { id: "male", name: "Male" },
                    { id: "female", name: "Female" },
                  ]}
                  optionText="name"
                  fullWidth
                  variant="outlined"
                />
              </Box>
            </Box>
            <TextInput
              type="email"
              source="email"
              resource="students"
              validate={[email(), required()]}
              fullWidth
              variant="outlined"
            />
            <TextInput
              type="alternative_email"
              source="alt_email"
              resource="students"
              validate={email()}
              fullWidth
              variant="outlined"
            />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="birth_date"
                  resource="students"
                  fullWidth
                  variant="outlined"
                  validate={required()}
                />
              </Box>
              <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
            </Box>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <TextInput
              source="street_address"
              resource="students"
              multiline
              fullWidth
              variant="outlined"
              validate={required()}
            />
            <TextInput
              source="additional_address"
              resource="students"
              multiline
              variant="outlined"
              fullWidth
            />
            <TextInput
              source="mailing_address"
              resource="students"
              multiline
              fullWidth
              variant="outlined"
            />

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              Phones
            </Typography>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="mobile_phone"
                  resource="students"
                  variant="outlined"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source="home_phone"
                  variant="outlined"
                  resource="students"
                  fullWidth
                />
              </Box>
              <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="fax"
                  resource="students"
                  fullWidth
                  variant="outlined"
                />
              </Box>
            </Box>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              Organization Information
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                {/* <ReferenceInput
                  source="country_id"
                  reference="countries"
                  validate={required()}
                > */}
                <SelectInput
                  source="country_id"
                  optionText="name"
                  fullWidth
                  variant="outlined"
                  choices={COUNTRIES}
                />
                {/* </ReferenceInput> */}
                <TextInput source="organization" variant="outlined" fullWidth />
                {/* <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <ReferenceInput
                      source="organization_id"
                      reference="organizations"
                      validate={required()}
                      filter={{
                        country_id: formData && formData.country_id,
                      }}
                    >
                      <SelectInput
                        optionText="name"
                        fullWidth
                        variant="outlined"
                      />
                    </ReferenceInput>
                  )}
                </FormDataConsumer> */}
                {/* <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <ReferenceInput
                      source="program_id"
                      reference="programs"
                      filter={{
                        status: "published",
                        organization_id: formData && formData.organization_id,
                      }}
                      validate={required()}
                    >
                      <SelectInput
                        optionText="name"
                        fullWidth
                        variant="outlined"
                      />
                    </ReferenceInput>
                  )}
                </FormDataConsumer> */}
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="position"
                  resource="students"
                  variant="outlined"
                  fullWidth
                />
                <TextInput
                  source="organization_details"
                  resource="students"
                  fullWidth
                  variant="outlined"
                />
              </Box>
            </Box>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              Educational Information
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="education_summary"
                  variant="outlined"
                  resource="students"
                  fullWidth
                />
                <TextInput
                  source="current_employment_summary"
                  resource="students"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default StudentRegistration;
