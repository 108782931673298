import { TOKEN } from "../../constants/auth";
import { fetchUtils } from "ra-core";
import { stringify } from "query-string";
import lodash from "lodash";

function cleanEmptyValues(values) {
  function omitFromObject(obj) {
    return lodash.transform(obj, function (result, value, key) {
      if (lodash.isNull(value) || lodash.isUndefined(value) || !value) {
        return;
      }

      result[key] = lodash.isObject(value) ? omitFromObject(value) : value;
    });
  }

  return omitFromObject(values);
}

const confertToMultiPart = (item) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(item instanceof File ? item : item.rawFile);

    reader.onload = () => resolve({ src: reader.result, title: item.name });
    reader.onerror = reject;
  });

const defaultHttpMediaClient = (url, options = {}) => {
  const token = localStorage.getItem(TOKEN);
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetch(url, options);
};

const defaultHttpClient = (url, options = {}) => {
  const token = localStorage.getItem(TOKEN);

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

export default (apiUrl, httpClient = defaultHttpClient) => {
  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort_field: field,
        sort_order: order,
        page: page,
        per_page: perPage,
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        if (!headers.has("content-range")) {
          throw new Error(
            "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
          );
        }
        return {
          data: json,
          total: parseInt(headers.get("content-range").split("/").pop(), 10),
        };
      });
    },

    getOne: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
        data: json,
      })),

    getMedia: (link) =>
      defaultHttpMediaClient(`${apiUrl}/${link}`)
        .then((res) => res.blob())
        .then((blob) => ({ data: URL.createObjectURL(blob) })),

    getMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort_field: field,
        sort_order: order,
        page: page,
        per_page: perPage,
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        if (!headers.has("content-range")) {
          throw new Error(
            "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
          );
        }
        return {
          data: json,
          total: parseInt(headers.get("content-range").split("/").pop(), 10),
        };
      });
    },

    update: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(cleanEmptyValues(params.data)),
      }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(cleanEmptyValues(params.data)),
          })
        )
      ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
      httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      })),

    delete: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
          })
        )
      ),
    uploadMedia: (resource, params) => {
      return confertToMultiPart(params.data && params.data.media).then(
        (convertedMedia) => {
          const options = {};
          params.data.media = convertedMedia.src;

          return defaultHttpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data),
            ...options,
          })
            .then(({ json }) => ({ data: json }))
            .catch((err) => {});
        }
      );
    },
    deleteMedia: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
        method: "DELETE",
      })
        .then(({ json }) => ({ data: json }))
        .catch((err) => {});
    },

    export: (resource, params) => {
      return httpClient(`${apiUrl}/reports/export`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        const linkSource = `data:application/pdf;base64,${json.file_content}`;
        const downloadLink = document.createElement("a");
        const fileName = `${params.data.export_name || "export"}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        return { data: json };
      });
    },
  };
};
