import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  TopToolbar,
  Filter,
  sanitizeListRestProps,
  ReferenceInput,
  ListBase,
  SelectInput,
  useListContext,
} from "react-admin";

import Empty from "./Empty";
import { ExportPdfButton } from "./ReportActions";
import React, { useEffect } from "react";
import { STATIC_URL } from "../../../constants/config";
import moment from "moment";
import { GetReference, parseQuery } from "../../../helpers";
import { Box, Typography } from "@material-ui/core";
import { GoToProgramButton } from "../StudentEnrollments/StudentEnrollmentActions";
import { useSelector } from "react-redux";

const GradesActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  program_id,
  ...rest
}) => {
  const getGradeContent = () => {
    const gradesList = document.getElementById("grades-list");
    return gradesList ? gradesList.outerHTML : null;
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      { program_id && <GoToProgramButton program_id={program_id} /> }
      <ExportPdfButton getContent={getGradeContent} exportTitle="Grades Report" />
    </TopToolbar>
  );
};

const GradesFilter = (props) => {
  const { setFilters } = useListContext();

  useEffect(() => {
    return () => {
      setFilters({});
    };
  }, []);
  
  return (
    <Filter {...props}>
      {GetReference("programs") && (
        <ReferenceInput
          alwaysOn
          allowEmpty={true}
          label="Program"
          source="program_id"
          reference="programs"
        >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export const Grades = (props) => {
  const { staticContext, location, ...rest } = props;
  const { program_id } = props.match.params;
  const params = parseQuery(location.search.substr(1).split("&"));
  const filter = params.filter ? JSON.parse(params.filter) : {};
  const {loading} = useSelector((state) => state.admin);

  
  if (program_id) {
    return (
      <List
        perPage={1000}
        bulkActionButtons={false}
        filter={{ status: "graduated", program_id: program_id }}
        pagination={<Empty />}
        actions={<GradesActions program_id={program_id} />}
        {...rest}
      >
        <Datagrid id="grades-list">
          <FunctionField
            label="Student"
            sortable={false}
            render={(record) =>
              record && record.student && record.student.fullname
            }
          />
          <FunctionField
            label="Grade"
            sortable={false}
            render={(record) =>
              record &&
              record.program &&
              ` ${moment(record.program.end_date, "YYYY-MM-DD").format(
                "YYYY"
              )} ${record.program.program_type}   ${record.grade}  (${
                record.rank
              })`
            }
          />
        </Datagrid>
      </List>
    );
  }

  return filter.program_id && !loading? (
    <List
      perPage={1000}
      bulkActionButtons={false}
      filter={{ status: "graduated", program_id: filter.program_id }}
      filters={<GradesFilter />}
      pagination={<Empty />}
      actions={<GradesActions program_id={program_id}/>}
      {...rest}
    >
      <Datagrid id="grades-list">
        <FunctionField
          label="Student"
          sortable={false}
          render={(record) =>
            record && record.student && record.student.fullname
          }
        />
        <FunctionField
          label="Grade"
          sortable={false}
          render={(record) =>
            record &&
            record.program &&
            ` ${moment(record.program.end_date, "YYYY-MM-DD").format("YYYY")} ${
              record.program.program_type
            }   ${record.grade}  (${record.rank})`
          }
        />
      </Datagrid>
    </List>
  ) : (
    <List
      perPage={1000}
      bulkActionButtons={false}
      filter={{ status: "graduated" }}
      filters={<GradesFilter />}
      pagination={<Empty />}
      actions={<GradesActions program_id={program_id}/>}
      {...rest}
    >
      <ListBase>
        <h1>{props.title}</h1>
        <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            Please select Program first.
          </Typography>
        </Box>
      </ListBase>
    </List>
  );
};
