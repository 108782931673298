import { Button, Datagrid, DateField, Link, List, SelectField, ShowButton, TextField } from 'react-admin';
import { Drawer, withStyles } from '@material-ui/core';
import React, { Fragment } from 'react';

import { ENROLLMENT_STATUS } from '../../../constants/common';
import { EnrollmentListActions } from './StudentEnrollmentActions';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Route } from 'react-router';
import StudentEnrollmentEdit from './StudentEnrollmentEdit';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { generateChoices } from '../../../helpers';
import { push } from 'react-router-redux';
import { stringify } from 'query-string';

const styles = {
    drawerContent: {
        width: 300,
    },
};

const GradeButton = ({ record }) =>
    record.status === 'funding_confirmed' ? (
        <Button
            label='Grade'
            component={Link}
            to={{
                pathname: `/student-enrollments/${record.id}`,
                search: '',
            }}
        >
            <HowToRegIcon />
        </Button>
    ) : null;

const StudentEnrollmentList = (props) => {
    const handleClose = () => {
        props.push(`/student-enrollments${props.location.search}`);
    };

    return (
        <Fragment>
            <List {...props} actions={<EnrollmentListActions location={props.location} />}>
                <Datagrid>
                    <TextField label='Fullname' source='student.fullname' />
                    <TextField label='Program name' source='program.name' />
                    <DateField label='Program start date' source='program.start_date' />
                    <DateField label='Program end date' source='program.end_date' />
                    <SelectField source='status' choices={generateChoices(ENROLLMENT_STATUS)} />
                    {/* <DateField label='Submission Date' source='created_on' /> */}
                    <TextField source='grade' />
                    <TextField source='rank' />
                    <ShowButton />
                    <GradeButton />
                </Datagrid>
            </List>
            <Route path='/student-enrollments/:id'>
                {({ match }) => {
                    const isMatch = match && match.params && match.params.id !== 'create';

                    return (
                        <Drawer open={isMatch} anchor='right'>
                            {isMatch ? (
                                <StudentEnrollmentEdit
                                    className={props.classes.drawerContent}
                                    id={match.params.id}
                                    onCancel={handleClose}
                                    {...props}
                                />
                            ) : (
                                <div className={props.classes.drawerContent} />
                            )}
                        </Drawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default compose(connect(undefined, { push }), withStyles(styles))(StudentEnrollmentList);
