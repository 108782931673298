import { Divider, Drawer } from '@material-ui/core';
import { Profile, SidebarNav, UpgradePlan } from './components';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImageIcon from '@material-ui/icons/Image';
import Language from '@material-ui/icons/Language';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)',
        },
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    nav: {
        marginBottom: theme.spacing(2),
    },
}));

const Sidebar = (props) => {
    const { open, variant, onClose, className, ...rest } = props;

    const classes = useStyles();

    const pages = [
        {
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />,
        },
        {
            title: 'Reports',
            href: '/reports',
            icon: <DashboardIcon />,
            children: [
                {
                    title: 'Dashboard',
                    href: '/dashboard',
                    icon: <DashboardIcon />,
                },
            ]
        },
        {
            title: 'Countries',
            href: '/countries',
            icon: <Language />,
        },
        {
            title: 'Students',
            href: '/students',
            icon: <PeopleIcon />,
        },
        {
            title: 'Programs',
            href: '/programs',
            icon: <Language />,
        },
        {
            title: 'Organizations',
            href: '/organizations',
            icon: <Language />,
        },
        {
            title: 'Users',
            href: '/users',
            icon: <AccountBoxIcon />,
        },
    ];

    return (
        <Drawer anchor='left' classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
            <div {...rest} className={clsx(classes.root, className)}>
                <Profile />
                <Divider className={classes.divider} />
                <SidebarNav className={classes.nav} pages={pages} />
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

export default Sidebar;
