// import StudentEnrollmentCreate from './StudentEnrollmentCreate';
// import StudentEnrollmentEdit from './StudentEnrollmentEdit';
import StudentEnrollmentList from './StudentEnrollmentList';
import StudentEnrollmentShow from './StudentEnrollmentShow';

export default {
    list: StudentEnrollmentList,
    show: StudentEnrollmentShow,
    // create: StudentEnrollmentCreate,
    // edit: StudentEnrollmentEdit,
};
