import { ReportsList } from './ReportsList';

export { Alumni } from './Alumni';
export { Country } from './Country';
export { Roster } from './Roster';


export default {
    list: ReportsList,
    // show: StudentEnrollmentShow,
    // create: StudentEnrollmentCreate,
    // edit: StudentEnrollmentEdit,
};