import {
  DateField,
  EmailField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  FormDataConsumer,
  FunctionField
} from "react-admin";

import Avatar from "../../components/Avatar";
import React, { Fragment } from "react";
import { Box, Typography, CardContent, Card, Grid } from "@material-ui/core";

const Enrollments = (props) => {
  const data =
    props.record &&
    props.record.enrollments.map((item) => {
      return (
        <div
          style={{
            paddingBottom: 20,
            marginBottom: 20,
            borderBottom: "1px solid grey",
          }}
        >
          <p>{item.program && item.program.program_type}</p>
          <p>{item.program && item.program.name}</p>
          <p>{item.program && item.program.stream}</p>
          <p>
            {item.program &&
              `from ${item.program.start_date} to ${item.program.end_date}`}
          </p>
          {item.rank && <p>Rank: {item.rank}</p>}
          {item.grade && <p>Grade: {item.grade}</p>}
        </div>
      );
    });

  return (
    <Card style={{ margin: "0px 20px", width: "30%" }}>
      <CardContent>
        <Typography variant="h5">Student programs</Typography>
        <hr />
        <br />
        {data}
      </CardContent>
    </Card>
  );
};

const StudentShow = (props) => (
  <Show {...props} aside={<Enrollments />}>
    <TabbedShowLayout>
      <Tab label="Personal Information">
        <Avatar {...props} />
        <TextField source="id" />
        <TextField source="fullname" />
        <TextField source="position" />
        <TextField source="organization" label="Initial organization" />
        <TextField source="organization_details.name" />
        <TextField source="citizenship" />
        <DateField source="birth_date" />
        <TextField source="gender" />
      </Tab>
      <Tab label="Contact Information">
        <TextField source="mailing_address" />
        <TextField source="street_address" />
        <TextField source="additional_address" />
        <TextField source="office_phone" />
        <TextField source="home_phone" />
        <TextField source="mobile_phone" />
        <TextField source="fax" />
        <EmailField source="email" />
        <TextField source="alternative_email" />
      </Tab>
      <Tab label="Education and Employment">
        <TextField source="current_employment_summary" />
        <TextField source="education_summary" />
        <TextField source="funding_source" />
        <TextField source="referrer" />
        <FunctionField
          source="total_students"
          label="Total Students"
          render={(record) => (
            <div>
              {record && record.media && record.media.length !== 0 ? (
                <ul style={{ listStyleType: "none" }}>
                  {record &&
                    record.media
                      .filter(
                        (file) =>
                          file.meta &&
                          JSON.parse(file.meta) &&
                          JSON.parse(file.meta).tag === "transcript"
                      )
                      .map((item) => (
                        <li>
                          <a href={item.link}>{item.title}</a>
                        </li>
                      ))}
                </ul>
              ) : (
                <p>No transcript</p>
              )}
            </div>
          )}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default StudentShow;
