import { Button, ExportButton, TopToolbar, sanitizeListRestProps } from 'react-admin';

import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import { parse } from 'query-string';

export const EnrollmentListActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    location,
    total,
    ...rest
}) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <GoToProgramButton location={location} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
    </TopToolbar>
);

export const GoToProgramButton = ({ location, program_id }) => {
    if (!program_id) {
        const params = parse(location.search);
        const filter = params.filter ? JSON.parse(params.filter) : {};

        program_id = filter.program_id;
    }
    return program_id ? (
        <Button
            label='Go to Program'
            component={Link}
            to={{
                pathname: `/programs/${program_id}/show`,
            }}
        >
            <AssignmentIcon />
        </Button>
    ) : null;
};

export const GoToStudentButton = ({ record }) => (
    <Button
        label='Go to Student'
        component={Link}
        to={{
            pathname: `/students/${record.student_id}/show`,
        }}
    >
        <PersonIcon />
    </Button>
);
