import "./styles.css";

import React from "react";
import lodash from "lodash";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

const Avatar = ({ width = 250, ...props }) => {
  const avatarMedia =
    props.record &&
    props.record.media &&
    lodash
      .sortBy(
        props.record.media.filter((item) => {
          const meta = JSON.parse(item.meta);
          return meta && meta.tag === "avatar";
        }),
        "id"
      )
      .reverse()[0];

  return (
    <div style={{ textAlign: "center" }}>
      {avatarMedia && avatarMedia.link ? (
        <img width={width} src={avatarMedia.link} alt="avatar" />
      ) : (
        <PhotoCameraIcon style={{ fontSize: 150 }} />
      )}
    </div>
  );
};

export default Avatar;
