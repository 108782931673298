import { Button, Edit, SaveButton, SimpleForm, TextInput, Toolbar, TopToolbar } from 'react-admin';

import React from 'react';

const StudentEnrollmentToolbar = ({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        <Button onClick={onCancel} label='Cancel' />
    </Toolbar>
);

const StudentEnrollmentActions = ({ onCancel, translate, ...props }) => <TopToolbar {...props} />;

const StudentEnrollmentEdit = ({ onCancel, ...props }) => (
    <Edit {...props} actions={<StudentEnrollmentActions />}>
        <SimpleForm toolbar={<StudentEnrollmentToolbar onCancel={onCancel} />}>
            <TextInput source='rank' />
            <TextInput source='grade' />
        </SimpleForm>
    </Edit>
);

export default StudentEnrollmentEdit;
