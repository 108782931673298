import StudentsList from './StudentsList';
import StudentsEdit from './StudentsEdit';
import StudentsShow from './StudentsShow';
import StudentsCreate from './StudentsCreate';

export default {
    list: StudentsList,
    edit: StudentsEdit,
    show: StudentsShow,
    create: StudentsCreate
};
